import $ from 'jquery';
import gsap from 'gsap';

// Particles Hover States

$(document).ready(function(){
  $("#source1").hover(function(){
    $("#particle1").css("filter", "drop-shadow(0 0 .5rem yellow)");
    }, function(){
    $("#particle1").css("filter", "none");
  });
  $("#source2").hover(function(){
      $("#particle2").css("filter", "drop-shadow(0 0 .5rem yellow)");
      }, function(){
      $("#particle2").css("filter", "none");
  });
  $("#source3").hover(function(){
      $("#particle3").css("filter", "drop-shadow(0 0 .5rem yellow)");
      }, function(){
      $("#particle3").css("filter", "none");
  });
  $("#source4").hover(function(){
      $("#particle4").css("filter", "drop-shadow(0 0 .5rem yellow)");
      }, function(){
      $("#particle4").css("filter", "none");
  });
  $("#source5").hover(function(){
      $("#particle5").css("filter", "drop-shadow(0 0 .5rem yellow)");
      }, function(){
      $("#particle5").css("filter", "none");
  });
  $("#source6").hover(function(){
      $("#particle6").css("filter", "drop-shadow(0 0 .5rem yellow)");
      }, function(){
      $("#particle6").css("filter", "none");
  });
    
});

// Setup Animation Particles

var tl = gsap.timeline ()
tl.to('.ball',{
x: () => gsap.utils.random(-3.5, 3.5) + "vw", //chooses a random number between -20 and 20 for each target, rounding to the closest 3.5!
y: () => gsap.utils.random(-3.5, 3.5) + "vw",
duration: 8,
stagger: 0.5,
ease:"power1.inOut",
repeat:-1,
repeatRefresh:true// gets a new random x and y value on each repeat
}, 0);
tl.to('.ball img',{
rotation: () => gsap.utils.random(-45, 45),
duration: 15,
stagger: 0.5,
ease:"power1.inOut",
repeat:-1,
repeatRefresh:true
}, 0);